* {
  box-sizing: border-box;
}

html {
  background-color: #FEFCF5;
  background: rgb(251, 228, 190);
  font-size: 16px;
}

.toptube {
  position: fixed;
  top: 0;
  width: 100%;
  display: flex;
  align-items: center;
  height: 4.5rem;
  border: solid 3px #7E030D;
  background: linear-gradient(135deg, #7E030D 26%, white 26% 38%, #FFDA58 38% 50%, #FF9658 50% 62%, #FF4E4E 62% 74%, #7E030D 74% 100%);
  z-index: 4;
}

.links {
  width: 60%;
  margin-left: 20%;
  display: flex;
  justify-content: space-evenly;
}

.icon {
  max-height: 3.5rem;
  max-width: 8vw;
}

.icon:hover {
  background: rgb(251, 122, 2);
}

.main {
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 5rem;
  width: 85vw;
  margin-left: 7.5vw;
  border-radius: 15px;
}

.name {
  font-size: 5vw;
  font-family: 'Sonsie One', cursive;
  color: #F83A00;
  text-shadow: -0.3rem 0.18rem rgb(78, 1, 8);
}

h1 {
  font-size: 2.7vw;
  font-weight: bold;
  text-align: center;
  color: #F83A00;
}

.chevron-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 45vh;
}

.chevron {
  position: relative;
  padding: 0 1.3vw;
  height: 90%;
  color: #fff;
  display: flex;
  align-items: center;
}

.chevron-inner{
  z-index: 2;
  padding: 0 20px 20px;
}

.chevron-content {
  z-index: 3;
  background: rgba(255, 255, 255, 0.712);
  border-radius: 15px;
  max-height: 40vh;
  margin-left: 2vw;
  margin-right: 5vw;
  padding: 1rem;
  color: black;
  font-size: 1.75vw;
}

.chevron:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 90%;
  -webkit-transform: skew(15deg, 0deg);
  -moz-transform: skew(15deg, 0deg);
  -ms-transform: skew(15deg, 0deg);
  -o-transform: skew(15deg, 0deg);
  transform: skew(15deg, 0deg);
}

.chevron:after {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 90%;
  -webkit-transform: skew(-15deg, 0deg);
  -moz-transform: skew(-15deg, 0deg);
  -ms-transform: skew(-15deg, 0deg);
  -o-transform: skew(-15deg, 0deg);
  transform: skew(-15deg, 0deg);
}

#chev1 {
  margin-left: 10vw;
}

#chev1:before {
  background: #FFDA58;
}

#chev1:after {
  background: #FFDA58;
}

#chev2:before {
  background: #FF9658;
}

#chev2:after {
  background: #FF9658;
}

#chev3:before {
  background: #FF4E4E;
}

#chev3:after {
  background: #FF4E4E;
}

#chev4 {
  width: 60vw;
}

#chev4:before {
  background: #7E030D;
}

#chev4:after {
  background: #7E030D;
}

#chev5:before {
  background: #FFFFFF;
}

#chev5:after {
  background: #FFFFFF;
}

#chev6 {
  width: 10vw;
}

#chev6:before {
  background: rgb(261, 228, 190);
  width: 10vw;
}

#chev6:after {
  background: rgb(251, 228, 190);
  width: 10vw;
}

#about {
  display: flex;
  background: #FEFCF5;
  margin-top: 10px;
  margin-bottom: 10px;
  border-top: solid 3px #7E030D;
  border-bottom: solid 3px #7E030D;
}

.info {
  font-family: 'Open Sans', sans-serif;
  font-size: 1.75vw;
  font-style: bold;
  margin-left: 15vw;
  margin-right: 15vw;
}

#portfolio {
  text-align: left;
  margin-left: 1vw;
}

.slider-wrapper {
  background: #FFFFFF;
  border-top: solid 3px #7E030D;
  border-bottom: solid 3px #7E030D;
}

.slide-container {
  display: flex;
  text-align: left;
  background: #FFFFFF;
  height: 100%;
}

.slide-image-container {
  width: 28vw;
  padding-left: 1vw;
}

.slide-image {
  max-width: 95%;
  height: auto;
  margin-top: 2rem; 
}

.slide-title {
  color: #7E030D;
  font-size: 2.5vw;
  margin-top: 1.4rem;
}

.slide-text {
  padding-left: 1rem;
  font-size: 1.75vw;
  width: 70vw;
}

#slide-header a{
  margin-right: 1.5rem;
  color: #f85a3e;
}

#slide-header a:hover {
  color: #FFDA58;
}

#footer {
  background: #7E030D;
  color: #FEFCF5;
  text-align: center;
  height: 5vh;
  width: 100%;
  margin-top: 0;
  bottom: 0;
}

#footer a {
  color: #FF9658;
}

@media only screen and (max-width: 600px) {
  .toptube {
    height: 3rem;
  }

  .icon {
    height: 1.3rem;
  }

  .main {
    margin-top: 3.5rem;
    width: 100%;
    margin-left: 0;
  }

  .name {
    font-size: 7vw;
  }

  h1 {
    font-size: 5vw;
  }

  .chevron:first-child {
    margin-top: 20px;
  }

  .chevron-container {
    flex-direction: column;
    align-items: center;
  }
  
  .chevron {
    position: relative;
    text-align: center;
    width: 70%;
  }
  
  .chevron:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 51%;
    -webkit-transform: skew(0deg, 8deg);
    -moz-transform: skew(0deg, 8deg);
    -ms-transform: skew(0deg, 8deg);
    -o-transform: skew(0deg, 8deg);
    transform: skew(0deg, 8deg);
  }
  
  .chevron:after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    width: 50%;
    -webkit-transform: skew(0deg, -8deg);
    -moz-transform: skew(0deg, -8deg);
    -ms-transform: skew(0deg, -8deg);
    -o-transform: skew(0deg, -8deg);
    transform: skew(0deg, -8deg);
  }

  .chevron-inner {
    height: auto;
  }

  .chevron-content {
    margin-top: 3vh;
    font-size: 3.5vw;
    padding: 1.5vw 1vw;
    margin-left: 0;
    margin-right: 0;
  }

  #chev1 {
    margin-left: 0;
  }

  #chev4 {
    width: 70%;
  }

  #chev6 {
    width: 70%;
  }

  #portfolio {
    margin-left: 3.5vw;
  }

  .slide-container {
    flex-direction: column;
  }

  .slide-image-container {
    width: 50vw;
    margin-left: 2vw;
  }

  .slide-image {
    margin-top: 1vh;
  }

  .slide-title {
    font-size: 4vw;
  }

  .slide-text {
    width: 95vw;
    font-size: 3.5vw;
  }

  #footer {
    height: 1.5rem;
    font-size: 0.5rem;
  }

  #footer div {
    margin-top: 0.3rem;
  }
}